import { ChainId, Token, WETH } from '@pancakeswap/sdk'

export const MAIN_TOKEN = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x654B1FB03E8d6eb241c80c1801888CE0932b09e0',
    18,
    'God Coins',
    'GOD',
    'https://hengcoin.com/'
  ),

  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xC7B81c2B856fF2614b882292e9b28061Ab2aa5c7',
    18,
    'God Coins',
    'GOD',
    'https://hengcoin.com/'
  )

}

export const MAIN_TOKEN2 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x1B5EB0CACC0aeD07e17CB4096ECfC6283ED6481C',
    18,
    'TDC',
    'Thai Digital Coins',
    'https://hengcoin.com/'
  ),

  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xF2857df33DA2aF3E45065ac6EF2Efd8c0997591F',
    18,
    'TDC',
    'Thai Digital Coins',
    'https://hengcoin.com/'
  )

}

export const USDT = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xC28112C2EC55aE1F15b78b51FC101b80eD1d529E',
    18,
    'USDT',
    'Tether USD',
    'https://www.centre.io/usdt'
  ),
}

export const BUSD = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
}


export const WBNB = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}


export const MAIN_TOKEN3 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    18,
    'DAI',
    'DAI Token',
    'https://mms-soilminerals.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867',
    18,
    'DAI',
    'DAI Token',
    'https://mms-soilminerals.com/'
  ),
}

export const MAIN_TOKEN4 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'ETH',
    'ETH Token',
    'https://mms-soilminerals.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378',
    18,
    'ETH',
    'ETH Token',
    'https://mms-soilminerals.com/'
  ),
}

export const SWAP = {
  [ChainId.BSC]: MAIN_TOKEN[ChainId.BSC],
  [ChainId.BSC_TESTNET]: MAIN_TOKEN[ChainId.BSC_TESTNET],
}

export const SWAP2 = {
  [ChainId.BSC]: USDT[ChainId.BSC],
  [ChainId.BSC_TESTNET]: USDT[ChainId.BSC_TESTNET],
}



export const bscTokens = {
  wbnb: WETH[ChainId.BSC],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC],
  busd: BUSD[ChainId.BSC],
  usdt: USDT[ChainId.BSC],
  bkr: MAIN_TOKEN2[ChainId.BSC],
  cake: MAIN_TOKEN[ChainId.BSC],
  mali: MAIN_TOKEN3[ChainId.BSC],
  cbn: MAIN_TOKEN4[ChainId.BSC]
}

export const bscTestnetTokens = {
  wbnb: WETH[ChainId.BSC_TESTNET],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC_TESTNET],
  busd: BUSD[ChainId.BSC_TESTNET],
  usdt: USDT[ChainId.BSC_TESTNET],
  bkr: MAIN_TOKEN2[ChainId.BSC_TESTNET],
  cake: MAIN_TOKEN[ChainId.BSC_TESTNET],
  mali: MAIN_TOKEN3[ChainId.BSC_TESTNET],
  cbn: MAIN_TOKEN4[ChainId.BSC_TESTNET]
}


export const USDW = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x1B5EB0CACC0aeD07e17CB4096ECfC6283ED6481C',
    18,
    'TDC',
    'Thai Digital Coins',
    'https://hengcoin.com/'
  ),

  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xF2857df33DA2aF3E45065ac6EF2Efd8c0997591F',
    18,
    'TDC',
    'Thai Digital Coins',
    'https://hengcoin.com/'
  )
}

